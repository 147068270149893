import { PATH_CUSTOMER, PATH_CUSTOMER_REGISTER } from "./paths";
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardCustomerRegisterLayout from "src/layouts/DashboardCustomerRegisterLayout";

// ----------------------------------------------------------------------

const AppCustomerRegisterRoutes = {
  path: PATH_CUSTOMER_REGISTER.root,
  guard: AuthProtect,
  layout: DashboardCustomerRegisterLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationForm,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/RetailerApplicationForm'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.form001,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/Form001'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.form002,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/Form002'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.form003,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/Form003'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.form004,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/Form004'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.form005,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/Form005'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.formSummary,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/FormSummary'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_REGISTER.general.retailerApplicationFormNew.formAgreement,
      component: lazy(() => import('src/views/CustomerRegisterDashboardGroup/RetailerApplicationForm/FormAgreementTermDistributor'))
    }
  ]
}

export default AppCustomerRegisterRoutes