import { sum, map, filter, uniqBy } from 'lodash';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  documentName: [],
  distributorCountry: '',
  personInCharge: '',
  phoneNo: '',
  businessName: '',
  companyName: '',
  companyRegistrationNumber: '',
  gstRegistrationNumber: '',
  businessType: '',
  businessDescription: '',
  businessOfficeAddress: '',
  officePhoneNumber: '',
  officeFaxNumber: '',
  companyWebsite: '',
  preferredLoginEmail: '',
  directorIC: [],
  businessRegistrationDocuments: [],

  // SHIPPING DETAILS
  shippingFirstName: '',
  shippingLastName: '',
  shippingAddressLine1: '',
  shippingAddressLine2: '',
  shippingPostCode: '',
  shippingCity: '',
  shippingCountry: '',
  shippingState: '',
  shippingMobileNumber: '',
  shippingEmailAddress: '',

  isBillingSameInfoShipping: true,

  // BILLING DETAILS
  billingFirstName: '',
  billingLastName: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingPostCode: '',
  billingCity: '',
  billingCountry: '',
  billingState: '',
  billingMobileNumber: '',
  billingEmailAddress: '',
  
  // CONTACT DETAILS
  directorName: '',
  directorNRIC: '',
  mobileNumber: '',
  emailAddress: '',

  // B2B AGREEMENT
  tradingTerms: false,
  privacyPolicy: false,

  // DISTRIBUTOR TERMS
  terms: [],

  retailerID: '',
  retailerApplicationFormId: '',
  b2bApplicationFormId: '',

  // FLOW TO KNOW FROM REJECT OR PENDING TO ACCESS THE SUBMITTED FORM WHEN REAPPLY OR EDIT FORM
  isFromReject: false
}

const slice = createSlice({
  name: 'applicationForm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setDistributorCountry(state, action) {
      state.isLoading = true;
      state.distributorCountry = action.payload;
    },

    setDocumentName(state, action) {
      state.isLoading = true;
      state.documentName = action.payload;
    },

    setPersonInCharge(state, action) {
      state.isLoading = true;
      state.personInCharge = action.payload;
    },

    setPhoneNo(state, action) {
      state.isLoading = true;
      state.phoneNo = action.payload;
    },

    setBusinessName(state, action) {
      state.isLoading = true;
      state.businessName = action.payload;
    },

    setCompanyName(state, action) {
      state.isLoading = true;
      state.companyName = action.payload;
    },

    setCompanyRegistrationNumber(state, action) {
      state.isLoading = true;
      state.companyRegistrationNumber = action.payload;
    },

    setGSTRegistrationNumber(state, action) {
      state.isLoading = true;
      state.gstRegistrationNumber = action.payload;
    },

    setBusinessType(state, action) {
      state.isLoading = true;
      state.businessType = action.payload;
    },

    setBusinessDescription(state, action) {
      state.isLoading = true;
      state.businessDescription = action.payload;
    },

    setBusinessOfficeAddress(state, action) {
      state.isLoading = true;
      state.businessOfficeAddress = action.payload;
    },

    setOfficePhoneNumber(state, action) {
      state.isLoading = true;
      state.officePhoneNumber = action.payload;
    },

    setOfficeFaxNumber(state, action) {
      state.isLoading = true;
      state.officeFaxNumber = action.payload;
    },

    setCompanyWebsite(state, action) {
      state.isLoading = true;
      state.companyWebsite = action.payload;
    },

    setPreferredLoginEmail(state, action) {
      state.isLoading = true;
      state.preferredLoginEmail = action.payload;
    },

    setDirectorIC(state, action) {
      state.isLoading = true;
      state.directorIC = action.payload;
    },

    setBusinessRegistrationDocuments(state, action) {
      state.isLoading = true;
      state.businessRegistrationDocuments = action.payload;
    },

    setDirectorName(state, action) {
      state.isLoading = true;
      state.directorName = action.payload;
    },

    setDirectorNRIC(state, action) {
      state.isLoading = true;
      state.directorNRIC = action.payload;
    },

    setMobileNumber(state, action) {
      state.isLoading = true;
      state.mobileNumber = action.payload;
    },

    setEmailAddress(state, action) {
      state.isLoading = true;
      state.emailAddress = action.payload;
    },

    // SHIPPING DETAILS
    setShippingFirstName(state, action) {
      state.isLoading = true;
      state.shippingFirstName = action.payload;
    },

    setShippingLastName(state, action) {
      state.isLoading = true;
      state.shippingLastName = action.payload;
    },

    setShippingAddressLine1(state, action) {
      state.isLoading = true;
      state.shippingAddressLine1 = action.payload;
    },

    setShippingAddressLine2(state, action) {
      state.isLoading = true;
      state.shippingAddressLine2 = action.payload;
    },

    setShippingPostCode(state, action) {
      state.isLoading = true;
      state.shippingPostCode = action.payload;
    },

    setShippingCity(state, action) {
      state.isLoading = true;
      state.shippingCity = action.payload;
    },

    setShippingCountry(state, action) {
      state.isLoading = true;
      state.shippingCountry = action.payload;
    },

    setShippingState(state, action) {
      state.isLoading = true;
      state.shippingState = action.payload;
    },

    setShippingMobileNumber(state, action) {
      state.isLoading = true;
      state.shippingMobileNumber = action.payload;
    },

    setShippingEmailAddress(state, action) {
      state.isLoading = true;
      state.shippingEmailAddress = action.payload;
    },

    setIsBillingSameInfoShipping(state, action) {
      state.isLoading = true;
      state.isBillingSameInfoShipping = action.payload;
    },

    // BILLING DETAILS
    setBillingFirstName(state, action) {
      state.isLoading = true;
      state.billingFirstName = action.payload;
    },

    setBillingLastName(state, action) {
      state.isLoading = true;
      state.billingLastName = action.payload;
    },

    setBillingAddressLine1(state, action) {
      state.isLoading = true;
      state.billingAddressLine1 = action.payload;
    },

    setBillingAddressLine2(state, action) {
      state.isLoading = true;
      state.billingAddressLine2 = action.payload;
    },

    setBillingPostCode(state, action) {
      state.isLoading = true;
      state.billingPostCode = action.payload;
    },

    setBillingCity(state, action) {
      state.isLoading = true;
      state.billingCity = action.payload;
    },

    setBillingCountry(state, action) {
      state.isLoading = true;
      state.billingCountry = action.payload;
    },

    setBillingState(state, action) {
      state.isLoading = true;
      state.billingState = action.payload;
    },

    setBillingMobileNumber(state, action) {
      state.isLoading = true;
      state.billingMobileNumber = action.payload;
    },

    setBillingEmailAddress(state, action) {
      state.isLoading = true;
      state.billingEmailAddress = action.payload;
    },

    // TRADING TERMS
    setTradingTerms(state, action) {
      state.isLoading = true;
      state.tradingTerms = action.payload;
    },

    setPrivacyPolicy(state, action) {
      state.isLoading = true;
      state.privacyPolicy = action.payload;
    },
    
    setTerms(state, action) {
      state.isLoading = true;
      state.terms = action.payload;
    },

    setRetailerID(state, action) {
      state.isLoading = true;
      state.retailerID = action.payload;
    },

    setRetailerApplicationFormId(state, action) {
      state.isLoading = true;
      state.retailerApplicationFormId = action.payload;
    },

    setIsFromReject(state, action) {
      state.isLoading = true;
      state.isFromReject = action.payload;
    },

    setB2BApplicationFormId(state, action) {
      state.isLoading = true;
      state.b2bApplicationFormId = action.payload;
    },

    resetApplicationForm(state) {
      state.personInCharge = ''
      state.phoneNo = ''
      state.businessName = ''
      state.companyName = ''
      state.companyRegistrationNumber = ''
      state.gstRegistrationNumber = ''
      state.businessType = ''
      state.businessDescription = ''
      state.businessOfficeAddress = ''
      state.officePhoneNumber = ''
      state.officeFaxNumber = ''
      state.companyWebsite = ''
      state.preferredLoginEmail = ''
      state.directorIC = []
      state.businessRegistrationDocuments = []

      state.shippingFirstName = ''
      state.shippingLastName = ''
      state.shippingAddressLine1 = ''
      state.shippingAddressLine2 = ''
      state.shippingPostCode = ''
      state.shippingCity = ''
      state.shippingCountry = ''
      state.shippingState = ''
      state.shippingMobileNumber = ''
      state.shippingEmailAddress = ''
    
      state.isBillingSameInfoShipping = true


      state.billingFirstName = ''
      state.billingLastName = ''
      state.billingAddressLine1 = ''
      state.billingAddressLine2 = ''
      state.billingPostCode = ''
      state.billingCity = ''
      state.billingCountry = ''
      state.billingState = ''
      state.billingMobileNumber = ''
      state.billingEmailAddress = ''
      
      // CONTACT DETAILS
      state.directorName = ''
      state.directorNRIC = ''
      state.mobileNumber = ''
      state.emailAddress = ''
    
      // B2B AGREEMENT
      state.tradingTerms = false
      state.privacyPolicy = false
      
      state.retailerID = ''
      state.retailerApplicationFormId = ''
      state.b2bApplicationFormId = ''

      state.isFromReject = ''
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

// ----------------------------------------------------------------------

export function setDistributorCountry(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDistributorCountry(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


export function setDocumentName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDocumentName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


export function setPersonInCharge(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPersonInCharge(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPhoneNo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPhoneNo(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


export function setBusinessName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBusinessName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCompanyName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCompanyName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCompanyRegistrationNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCompanyRegistrationNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setGSTRegistrationNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setGSTRegistrationNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBusinessType(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBusinessType(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBusinessDescription(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBusinessDescription(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBusinessOfficeAddress(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBusinessOfficeAddress(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfficePhoneNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfficePhoneNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfficeFaxNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfficeFaxNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCompanyWebsite(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCompanyWebsite(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPreferredLoginEmail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPreferredLoginEmail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setDirectorIC(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDirectorIC(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBusinessRegistrationDocuments(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBusinessRegistrationDocuments(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}



export function setDirectorName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDirectorName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setDirectorNRIC(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDirectorNRIC(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setMobileNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setMobileNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setEmailAddress(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setEmailAddress(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingFirstName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingFirstName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingLastName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingLastName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingAddressLine1(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingAddressLine1(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingAddressLine2(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingAddressLine2(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingPostCode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingPostCode(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingCity(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingCity(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingCountry(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingCountry(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingState(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingState(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingMobileNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingMobileNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setShippingEmailAddress(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setShippingEmailAddress(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsBillingSameInfoShipping(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsBillingSameInfoShipping(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


export function setBillingFirstName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingFirstName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingLastName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingLastName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingAddressLine1(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingAddressLine1(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingAddressLine2(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingAddressLine2(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingPostCode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingPostCode(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingCity(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingCity(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingCountry(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingCountry(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingState(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingState(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingMobileNumber(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingMobileNumber(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setBillingEmailAddress(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBillingEmailAddress(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setTradingTerms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setTradingTerms(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPrivacyPolicy(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPrivacyPolicy(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setTerms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setTerms(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setRetailerID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setRetailerID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setRetailerApplicationFormId(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setRetailerApplicationFormId(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsFromReject(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsFromReject(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setB2BApplicationFormId(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setB2BApplicationFormId(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetApplicationForm() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetApplicationForm())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


