// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs',
  sales: '/sales',
  admin: '/admin',
  finance: '/finance',
  logistic: '/logistic',
  adminKC: '/adminKC',
  homepage: '/',
  //customer
  customer: '/customer',
  customerRegister: '/customer-register',
  customerAppReview: '/customer-application-review',
  // customer-mobile
  customerMobile: '/customer-mobile',
  customerMobileRegister: '/customer-mobile-register',
  customerMobileAppReview: '/customer-mobile-application-review',

  // staff
  staff: '/staff'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login/:token'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify'),
    homepage: ROOTS.homepage
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  app: {
    mail: {
      root: path(ROOTS.app, '/mail'),
      all: path(ROOTS.app, '/mail/all'),
      labels: [
        path(ROOTS.app, '/mail/label/:customLabel/:mailId?'),
        path(ROOTS.app, '/mail/:systemLabel/:mailId?')
      ]
    },
    chat: {
      root: path(ROOTS.app, '/chat'),
      new: path(ROOTS.app, '/chat/new'),
      conversation: [
        path(ROOTS.app, '/chat/new'),
        path(ROOTS.app, '/chat/:conversationKey')
      ]
    },
    calendar: path(ROOTS.app, '/calendar')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
    eCommerce: {
      root: path(ROOTS.app, '/management/e-commerce'),
      products: path(ROOTS.app, '/management/e-commerce/products'),
      product: path(ROOTS.app, '/management/e-commerce/product/:name'),
      productById: path(
        ROOTS.app,
        '/management/e-commerce/product/nike-air-force-1-ndestrukt'
      ),
      list: path(ROOTS.app, '/management/e-commerce/list'),
      checkout: path(ROOTS.app, '/management/e-commerce/checkout'),
      invoice: path(ROOTS.app, '/management/e-commerce/invoice')
    },
    blog: {
      root: path(ROOTS.app, '/management/blog'),
      post: path(ROOTS.app, '/management/blog/post/:title'),
      postById: path(
        ROOTS.app,
        '/management/blog/post/portfolio-review-is-this-portfolio-too-creative'
      ),
      newPost: path(ROOTS.app, '/management/blog/new-post')
    }
  },
  foundations: {
    root: path(ROOTS.app, '/foundations'),
    colors: path(ROOTS.app, '/foundations/colors'),
    typography: path(ROOTS.app, '/foundations/typography'),
    shadows: path(ROOTS.app, '/foundations/shadows'),
    grid: path(ROOTS.app, '/foundations/grid'),
    icons: path(ROOTS.app, '/foundations/icons')
  },
  components: {
    root: path(ROOTS.app, '/components'),
    accordion: path(ROOTS.app, '/components/accordion'),
    alert: path(ROOTS.app, '/components/alert'),
    autocomplete: path(ROOTS.app, '/components/autocomplete'),
    avatar: path(ROOTS.app, '/components/avatars'),
    badge: path(ROOTS.app, '/components/badges'),
    breadcrumbs: path(ROOTS.app, '/components/breadcrumbs'),
    buttons: path(ROOTS.app, '/components/buttons'),
    chip: path(ROOTS.app, '/components/chips'),
    dialog: path(ROOTS.app, '/components/dialogs'),
    textfield: path(ROOTS.app, '/components/text-fields'),
    label: path(ROOTS.app, '/components/labels'),
    lists: path(ROOTS.app, '/components/lists'),
    menu: path(ROOTS.app, '/components/menu'),
    pagination: path(ROOTS.app, '/components/pagination'),
    pickers: path(ROOTS.app, '/components/pickers'),
    popover: path(ROOTS.app, '/components/popover'),
    progress: path(ROOTS.app, '/components/progress'),
    rating: path(ROOTS.app, '/components/rating'),
    selectionControls: path(ROOTS.app, '/components/selection-controls'),
    snackbar: path(ROOTS.app, '/components/snackbars'),
    slider: path(ROOTS.app, '/components/slider'),
    stepper: path(ROOTS.app, '/components/steppers'),
    tabs: path(ROOTS.app, '/components/tabs'),
    table: path(ROOTS.app, '/components/table'),
    timeline: path(ROOTS.app, '/components/timeline'),
    tooltip: path(ROOTS.app, '/components/tooltips'),
    transferList: path(ROOTS.app, '/components/transfer-list'),
    treeView: path(ROOTS.app, '/components/tree-view'),

    // Extra
    chart: {
      root: path(ROOTS.app, '/extra-components/chart'),
      apexcharts: path(ROOTS.app, '/extra-components/chart/apexcharts'),
      recharts: path(ROOTS.app, '/extra-components/chart/recharts')
    },
    map: {
      root: path(ROOTS.app, '/extra-components/map'),
      google: path(ROOTS.app, '/extra-components/map/googlemap'),
      mapbox: path(ROOTS.app, '/extra-components/map/mapbox')
    },
    editor: path(ROOTS.app, '/extra-components/editor'),
    copyToClipboard: path(ROOTS.app, '/extra-components/copy-to-clipboard'),
    upload: path(ROOTS.app, '/extra-components/upload'),
    carousel: path(ROOTS.app, '/extra-components/carousel'),
    multiLanguage: path(ROOTS.app, '/extra-components/multi-language')
  }
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  tips: path(ROOTS.docs, '/tips'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  lazyload: path(ROOTS.docs, '/lazyload-image'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};

export const PATH_SALES = {
  root: ROOTS.sales,
  general: {
    root: path(ROOTS.sales, '/dashboard'),
    dashboard: path(ROOTS.sales, '/dashboard'),
    //PRE ORDER
    preOrder: path(ROOTS.sales, '/pre-order'),
    addPreOrder: path(ROOTS.sales, '/pre-order/new'),
    preOrderDetails: {
      view: path(ROOTS.sales, '/pre-order/details/:preOrderID?')
    },

    //OFFERS
    offers: path(ROOTS.sales, '/offers'),
    addOffers: path(ROOTS.sales, '/offers/new'),
    offerDetails: {
      view: path(ROOTS.sales, '/offers/details/:offerID?'),
      product: path(ROOTS.sales, '/offers/details/:offerID?/:productID?')
    },

    //PRODUCTS
    products: path(ROOTS.sales, '/products'),
    addProduct: path(ROOTS.sales, '/products/new'),
    productDetails: {
      view: path(ROOTS.sales, '/products/details/:productID?')
    },

    //INVOICE
    invoice: path(ROOTS.sales, '/invoice'),
    addInvoice: path(ROOTS.sales, '/invoice/new'),

    //PAYMENT
    payment: path(ROOTS.sales, '/payment'),
    addPayment: path(ROOTS.sales, '/payment/new'),

    //RETAILER
    retailer: path(ROOTS.sales, '/retailer'),
    addRetailer: path(ROOTS.sales, '/retailer/new'),
    retailerDetails: {
      view: path(ROOTS.sales, '/retailer/details/:retailerID?'),
      edit: path(ROOTS.sales, '/retailer/details/:retailerID?/edit')
    },

    //SUPPLIER
    supplier: path(ROOTS.sales, '/supplier'),
    addSupplier: path(ROOTS.sales, '/supplier/new'),
    supplierDetails: {
      view: path(ROOTS.sales, '/supplier/details/:supplierID?')
    },

    //TAGS
    tags: path(ROOTS.sales, '/tags'),
    addTags: path(ROOTS.sales, '/tags/new'),
    tagsDetails: {
      view: path(ROOTS.sales, '/tags/details/:tagID?')
    },

    //PURCHASE ORDER
    purchaseOrder: path(ROOTS.sales, '/purchase-order'),
    addPurchaseOrder: path(ROOTS.sales, '/purchase-order/new')
  }
}

export const PATH_ADMIN = {
  root: ROOTS.admin,
  general: {
    root: path(ROOTS.admin, '/dashboard'),
    dashboard: path(ROOTS.admin, '/dashboard'),

    //ROLE
    role: path(ROOTS.admin, '/role'),
    addRole: path(ROOTS.admin, '/role/new'),
    roleDetails: {
      view: path(ROOTS.admin, '/role/details/:roleID?')
    },
    
    //USER
    user: path(ROOTS.admin, '/user'),
    addUser: path(ROOTS.admin, '/user/new'),
    userDetails: {
      view: path(ROOTS.admin, '/user/details/:userID?')
    },

    //DISTRIBUTOR
    distributor: path(ROOTS.admin, '/distributor'),
    addDistributor: path(ROOTS.admin, '/distributor/new')

  }
}

export const PATH_FINANCE = {
  root: ROOTS.finance,
  general: {
    root: path(ROOTS.finance, '/dashboard'),
    dashboard: path(ROOTS.finance, '/dashboard'),

    //INVOICE
    invoice: path(ROOTS.finance, '/invoice'),
    invoiceEdit: path(ROOTS.finance, '/invoice/edit')
  }
}

export const PATH_LOGISTIC = {
  root: ROOTS.logistic,
  general: {
    root: path(ROOTS.logistic, '/dashboard'),
    dashboard: path(ROOTS.logistic, '/dashboard'),

    //RMA
    rma: path(ROOTS.logistic, '/rma'),
    addRma: path(ROOTS.logistic, '/rma/new')
  }
}

export const PATH_ADMIN_KC = {
  root: ROOTS.adminKC,
  general: {
    root: path(ROOTS.adminKC, '/dashboard'),
    dashboard: path(ROOTS.adminKC, '/dashboard'),

    distributor: path(ROOTS.adminKC, '/distributor'),
    addDistributor: path(ROOTS.adminKC, '/distributor/new'),
    distributorDetails: {
      view: path(ROOTS.adminKC, '/distributor/details/:distributorID?')
    }
  }
}

export const PATH_CUSTOMER_REGISTER = {
  root: ROOTS.customerRegister,
  general: {
    root: path(ROOTS.customerRegister, '/retailer-application-form'),
    retailerApplicationForm: path(ROOTS.customerRegister, '/retailer-application-form'),
    retailerApplicationFormNew: {
      form001: path(ROOTS.customerRegister, '/retailer-application-form/section-001'),
      form002: path(ROOTS.customerRegister, '/retailer-application-form/section-002'),
      form003: path(ROOTS.customerRegister, '/retailer-application-form/section-003'),
      form004: path(ROOTS.customerRegister, '/retailer-application-form/section-004'),
      form005: path(ROOTS.customerRegister, '/retailer-application-form/section-005'),
      formSummary: path(ROOTS.customerRegister, '/retailer-application-form/section-summary'),
      formAgreement: path(ROOTS.customerRegister, '/retailer-application-form/section-agreement-terms')
    },
  }
}

export const PATH_CUSTOMER_MOBILE_REGISTER = {
  root: ROOTS.customerMobileRegister,
  general: {
    root: path(ROOTS.customerMobileRegister, '/retailer-application-form'),
    retailerApplicationForm: path(ROOTS.customerMobileRegister, '/retailer-application-form'),
    retailerApplicationFormNew: {
      form001: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-001'),
      form002: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-002'),
      form003: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-003'),
      form004: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-004'),
      form005: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-005'),
      formSummary: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-summary'),
      formAgreement: path(ROOTS.customerMobileRegister, '/retailer-application-form/section-agreement-terms')
    }
  }
}

export const PATH_CUSTOMER_APP_REVIEW = {
  root: ROOTS.customerAppReview,
  general: {
    root: path(ROOTS.customerAppReview, '/retailer-application-form'),
    retailerApplicationForm: path(ROOTS.customerAppReview, '/retailer-application-form'),
    retailerApplicationFormReApply: {
      form001: path(ROOTS.customerAppReview, '/retailer-application-form/section-001'),
      form002: path(ROOTS.customerAppReview, '/retailer-application-form/section-002'),
      form003: path(ROOTS.customerAppReview, '/retailer-application-form/section-003'),
      form004: path(ROOTS.customerAppReview, '/retailer-application-form/section-004'),
      form005: path(ROOTS.customerAppReview, '/retailer-application-form/section-005'),
      formSummary: path(ROOTS.customerAppReview, '/retailer-application-form/section-summary'),
      formAgreement: path(ROOTS.customerAppReview, '/retailer-application-form/section-agreement-terms')
    },


    //PROFILE
    profile: path(ROOTS.customerAppReview, '/profile'),
    editProfile: path(ROOTS.customerAppReview, '/profile/edit'),
    changeEmail: path(ROOTS.customerAppReview, '/profile/change-email'),
    verificationCode: path(ROOTS.customerAppReview, '/profile/verification-code/:email?'),
    changePassword: path(ROOTS.customerAppReview, '/profile/change-password'),

    //DISTRIBUTOR
    distributor: path(ROOTS.customerAppReview, '/distributor'),
    distributorDetail: path(ROOTS.customerAppReview, '/distributor/detail/:distributorID?')
  }
}

export const PATH_CUSTOMER_MOBILE_APP_REVIEW = {
  root: ROOTS.customerMobileAppReview,
  general: {
    root: path(ROOTS.customerMobileAppReview, '/retailer-application-form'),
    retailerApplicationForm: path(ROOTS.customerMobileAppReview, '/retailer-application-form'),
    retailerApplicationFormReApply: {
      form001: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-001'),
      form002: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-002'),
      form003: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-003'),
      form004: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-004'),
      form005: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-005'),
      formSummary: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-summary'),
      formAgreement: path(ROOTS.customerMobileAppReview, '/retailer-application-form/section-agreement-terms')
    },

    //PROFILE
    profile: path(ROOTS.customerMobileAppReview, '/profile'),
    editProfile: path(ROOTS.customerMobileAppReview, '/profile/edit'),
    changeEmail: path(ROOTS.customerMobileAppReview, '/profile/change-email'),
    verificationCode: path(ROOTS.customerMobileAppReview, '/profile/verification-code/:email?'),
    changePassword: path(ROOTS.customerMobileAppReview, '/profile/change-password'),

    //DISTRIBUTOR
    distributor: path(ROOTS.customerMobileAppReview, '/distributor'),
    distributorDetail: path(ROOTS.customerMobileAppReview, '/distributor/detail/:distributorID?')
  }
}

export const PATH_CUSTOMER = {
  root: ROOTS.customer,
  general: {
    root: path(ROOTS.customer, '/dashboard'),
    dashboard: path(ROOTS.customer, '/dashboard'),

    //NOTIFICATION
    notifications: path(ROOTS.customer, '/notifications'),
    notificationDetail: {
      view: path(ROOTS.customer, '/notifications/details/:notificationID?')
    },

    //OFFERS
    offers: path(ROOTS.customer, '/offers'),
    offersDetail: {
      view: path(ROOTS.customer, '/offers/view/:offerID?'),
      productDetail: path(ROOTS.customer, '/offers/view/:offerID?/productDetail/:productID?'),
      offerBasket: path(ROOTS.customer, '/offers/view/:offerID?/basket'),
      offerSuccess: path(ROOTS.customer, '/offers/view/apply/pre-order/success'),
      manageRating: path(ROOTS.customer, '/offers/view/:offerID?/manage-rating'),
      manageRecommendedCartonQty: path(ROOTS.customer, '/offers/view/:offerID?/manage-recommended-carton-qty')
    },

    //PRE ORDER
    preOrder: path(ROOTS.customer, '/pre-order'),
    preOrderDetail: {
      view: path(ROOTS.customer, '/pre-order/view/:preOrderID?'),
      viewOrderDetail: path(ROOTS.customer, '/pre-order/view/:preOrderID?/order-detail'),
      staffPreOrderBreakdown: path(ROOTS.customer, '/pre-order/view/:preOrderID?/staff-pre-order-breakdown'),
    },

    //CLOSED OFFERS
    closedOffers: path(ROOTS.customer, '/closed-offers'),
    closedOffersDetail: {
      view: path(ROOTS.customer, '/closed-offers/view/:offerID?')
    },

    //ALL ORDER
    orderAll: path(ROOTS.customer, '/order-all'),
    orderAllDetail: {
      view: path(ROOTS.customer, '/order-all/view/:invoiceID?')
    },
        
    //ORDER
    order: path(ROOTS.customer, '/order'),
    orderDetail: {
      view: path(ROOTS.customer, '/order/:invoiceID?')
    },

    //CONSIGNMENT
    consignment: path(ROOTS.customer, '/order-consignment'),
    consignmentDetail: {
      view: path(ROOTS.customer, '/order-consignment/view/:invoiceID?')
    },

    //PAYMENT
    payment: path(ROOTS.customer, '/payment'),
    paymentDetail: {
      view: path(ROOTS.customer, '/payment/view/:paymentID?')
    },

    //DISTRIBUTOR
    distributor: path(ROOTS.customer, '/distributor'),
    distributorView: path(ROOTS.customer, '/distributor/view/:distributorID?'),
    applyDistributor: path(ROOTS.customer, '/distributor/view/:distributorID?/apply'),
    distributorDetail: {
      viewAllProducts: path(ROOTS.customer, '/distributor/view/:distributorID?/all-products'),
    },
    viewApplicationForm: path(ROOTS.customer, '/distributor/view/:distributorID?/detail'),
    editApplicationForm: path(ROOTS.customer, '/distributor/view/:distributorID?/edit'),

    //REPORT
    report: path(ROOTS.customer, '/report'),

    //PROFILE
    profile: path(ROOTS.customer, '/profile'),
    editProfile: path(ROOTS.customer, '/profile/edit'),
    changeEmail: path(ROOTS.customer, '/profile/change-email'),
    verificationCode: path(ROOTS.customer, '/profile/verification-code/:email?'),
    changePassword: path(ROOTS.customer, '/profile/change-password'),

    //RETAILER APPLICATION FORM
    retailerApplicationForm: path(ROOTS.customer, '/retailer-application-form'),
    editRetailerApplicationForm: {
      form001: path(ROOTS.customer, '/retailer-application-form/edit/section-001'),
      form002: path(ROOTS.customer, '/retailer-application-form/edit/section-002'),
      form003: path(ROOTS.customer, '/retailer-application-form/edit/section-003'),
      form004: path(ROOTS.customer, '/retailer-application-form/edit/section-004'),
      form005: path(ROOTS.customer, '/retailer-application-form/edit/section-005'),
      formSummary: path(ROOTS.customer, '/retailer-application-form/edit/section-summary'),
      formAgreement: path(ROOTS.customer, '/retailer-application-form/edit/section-agreement-terms')
    },

    //CONSIGNMENT REPORT
    consignmentReport: path(ROOTS.customer, '/consignment-report'),
    addConsignmentReport: {
      sale: path(ROOTS.customer, '/consignment-report/add/sale'),
      addProduct: path(ROOTS.customer, '/consignment-report/add/sale/add-product'),
      auditStock: path(ROOTS.customer, '/consignment-report/add/audit-stock'),
      addProductForAudit: path(ROOTS.customer, '/consignment-report/add/audit-stock/add-product')
    },
    consignmentReportDetail: {
      view: path(ROOTS.customer, '/consignment-report/view/:reportID?')
    },

    //CONSIGNMENT STOCK RETURNABLE
    consignmentStockReturnable: path(ROOTS.customer, '/consignment-stock-returnable'),
    addConsignmentStockReturnable: {
      new: path(ROOTS.customer, '/consignment-stock-returnable/new'),
      addProduct: path(ROOTS.customer, '/consignment-stock-returnable/new/add-product'),
    },
    consignmentStockReturnableDetail: {
      view: path(ROOTS.customer, '/consignment-stock-returnable/view/:stockReturnableID?'),
      edit: path(ROOTS.customer, '/consignment-stock-returnable/view/:stockReturnableID?/edit')
    },

    // COMPANY ACCOUNT
    companyAccount: path(ROOTS.customer, '/company-account'),
    // STOCK PRODUCTS
    stockProducts: path(ROOTS.customer, '/stock-products'),
    stockProductsDetail: {
      view: path(ROOTS.customer, '/stock-products/view/:productID?')
    },

    // CONSIGNMENT SKU ORDER
    consignmentSkuOrder: path(ROOTS.customer, '/consignment-sku-order'),
    consignmentSkuOrderDetail: {
      view: path(ROOTS.customer, '/consignment-sku-order/view/:orderID?')
    },

    // STAFF
    staff: path(ROOTS.customer, '/manage-staff'),
    addStaff: path(ROOTS.customer, '/manage-staff/new'),
    staffDetail: {
      view: path(ROOTS.customer, '/manage-staff/view/:staffID?'),
      edit: path(ROOTS.customer, '/manage-staff/view/:staffID?/edit')
    },

    // STAFF PRE ORDER
    staffPreOrder: path(ROOTS.customer, '/staff-pre-order'),
    staffPreOrderDetail: {
      view: path(ROOTS.customer, '/staff-pre-order/view/:preOrderID?'),
      generatePreOrder: path(ROOTS.customer, '/staff-pre-order/generate-pre-order'),
      edit: path(ROOTS.customer, '/staff-pre-order/view/:preOrderID?/edit')
    },

    // CREDIT NOTE
    creditNote: path(ROOTS.customer, '/credit-note'),
    creditNoteDetail: {
      view: path(ROOTS.customer, '/credit-note/view/:creditNoteID?')
    }
  }
}

export const PATH_CUSTOMER_MOBILE = {
  root: ROOTS.customerMobile,
  general: {
    root: path(ROOTS.customerMobile, '/dashboard'),
    dashboard: path(ROOTS.customerMobile, '/dashboard'), 

    // NOTIFICATION
    notifications: path(ROOTS.customerMobile, '/notifications'),
    notificationDetail: {
      view: path(ROOTS.customerMobile, '/notifications/details/:notificationID?')
    },

    //OFFERS
    offers: path(ROOTS.customerMobile, '/offers'),
    offersDetail: {
      view: path(ROOTS.customerMobile, '/offers/view/:offerID?'),
      offerSuccess: path(ROOTS.customerMobile, '/offers/view/apply/pre-order/success'),
      manageRating: path(ROOTS.customerMobile, '/offers/view/:offerID?/manage-rating'),
      manageRecommendedCartonQty: path(ROOTS.customerMobile, '/offers/view/:offerID?/manage-recommended-carton-qty')
    },

    //PRE ORDER
    preOrder: path(ROOTS.customerMobile, '/pre-order'),
    preOrderDetail: {
      view: path(ROOTS.customerMobile, '/pre-order/view/:preOrderID?'),
      viewOrderDetail: path(ROOTS.customerMobile, '/pre-order/view/:preOrderID?/order-detail'),
      staffPreOrderBreakdown: path(ROOTS.customerMobile, '/pre-order/view/:preOrderID?/staff-pre-order-breakdown'),
    },

    //CLOSED OFFERS
    closedOffers: path(ROOTS.customerMobile, '/closed-offers'),
    closedOffersDetail: {
      view: path(ROOTS.customerMobile, '/closed-offers/view/:offerID?')
    },

    //ALL ORDER
    orderAll: path(ROOTS.customerMobile, '/order-all'),
    orderAllDetail: {
      view: path(ROOTS.customerMobile, '/order-all/view/:invoiceID?')
    },

    //ORDER
    order: path(ROOTS.customerMobile, '/order'),
    orderDetail: {
      view: path(ROOTS.customerMobile, '/order/:invoiceID?')
    },

    //CONSIGNMENT
    consignment: path(ROOTS.customerMobile, '/order-consignment'),
    consignmentDetail: {
      view: path(ROOTS.customerMobile, '/order-consignment/view/:invoiceID?')
    },
 
    //PAYMENT
    payment: path(ROOTS.customerMobile, '/payment'),
    paymentDetail: {
      view: path(ROOTS.customerMobile, '/payment/view/:paymentID?')
    },

    //DISTRIBUTOR
    distributor: path(ROOTS.customerMobile, '/distributor'),
    distributorView: path(ROOTS.customerMobile, '/distributor/view/:distributorID?'),
    applyDistributor: path(ROOTS.customerMobile, '/distributor/view/:distributorID?/apply'),

    //REPORT
    report: path(ROOTS.customerMobile, '/report'),

    //PROFILE
    profile: path(ROOTS.customerMobile, '/profile'),
    editProfile: path(ROOTS.customerMobile, '/profile/edit'),
    changeEmail: path(ROOTS.customerMobile, '/profile/change-email'),
    verificationCode: path(ROOTS.customerMobile, '/profile/verification-code/:email?'),
    changePassword: path(ROOTS.customerMobile, '/profile/change-password'),

    //RETAILER APPLICATION FORM
    retailerApplicationForm: path(ROOTS.customerMobile, '/retailer-application-form'),
    editRetailerApplicationForm: {
      form001: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-001'),
      form002: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-002'),
      form003: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-003'),
      form004: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-004'),
      form005: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-005'),
      formSummary: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-summary'),
      formAgreement: path(ROOTS.customerMobile, '/retailer-application-form/edit/section-agreement-terms')
    },

    //CONSIGNMENT REPORT
    consignmentReport: path(ROOTS.customerMobile, '/consignment-report'),
    addConsignmentReport: {
      sale: path(ROOTS.customerMobile, '/consignment-report/add/sale'),
      addProduct: path(ROOTS.customerMobile, '/consignment-report/add/sale/add-product'),
      auditStock: path(ROOTS.customerMobile, '/consignment-report/add/audit-stock'),
      addProductForAudit: path(ROOTS.customerMobile, '/consignment-report/add/audit-stock/add-product')
    },
    consignmentReportDetail: {
      view: path(ROOTS.customerMobile, '/consignment-report/view/:reportID?')
    },

    //CONSIGNMENT STOCK RETURNABLE
    consignmentStockReturnable: path(ROOTS.customerMobile, '/consignment-stock-returnable'),
    addConsignmentStockReturnable: {
      new: path(ROOTS.customerMobile, '/consignment-stock-returnable/new'),
      addProduct: path(ROOTS.customerMobile, '/consignment-stock-returnable/new/add-product'),
    },
    consignmentStockReturnableDetail: {
      view: path(ROOTS.customerMobile, '/consignment-stock-returnable/view/:stockReturnableID?'),
      edit: path(ROOTS.customerMobile, '/consignment-stock-returnable/view/:stockReturnableID?/edit')
    },

    // CONSIGNMENT SKU ORDER
    consignmentSkuOrder: path(ROOTS.customerMobile, '/consignment-sku-order'),
    consignmentSkuOrderDetail: {
      view: path(ROOTS.customerMobile, '/consignment-sku-order/view/:orderID?')
    },

    // CONSIGNMENT STOCK PRODUCTS
    stockProducts: path(ROOTS.customerMobile, '/stock-products'),
    stockProductsDetail: {
      view: path(ROOTS.customerMobile, '/stock-products/view/:productID?')
    },


    // STAFF
    staff: path(ROOTS.customerMobile, '/manage-staff'),
    addStaff: path(ROOTS.customerMobile, '/manage-staff/new'),
    staffDetail: {
      view: path(ROOTS.customerMobile, '/manage-staff/view/:staffID?'),
      edit: path(ROOTS.customerMobile, '/manage-staff/view/:staffID?/edit')
    },

    // STAFF PRE ORDER
    staffPreOrder: path(ROOTS.customerMobile, '/staff-pre-order'),
    staffPreOrderDetail: {
      view: path(ROOTS.customerMobile, '/staff-pre-order/view/:preOrderID?'),
      generatePreOrder: path(ROOTS.customerMobile, '/staff-pre-order/generate-pre-order'),
      edit: path(ROOTS.customerMobile, '/staff-pre-order/view/:preOrderID?/edit')
    },

    // CREDIT NOTE
    creditNote: path(ROOTS.customerMobile, '/credit-note'),
    creditNoteDetail: {
      view: path(ROOTS.customerMobile, '/credit-note/view/:creditNoteID?')
    }

  }
}

export const PATH_STAFF = {
  root: ROOTS.staff,
  general: {
    root: path(ROOTS.staff, '/offers'),

    // OFFERS
    offersDetail: {
      view: path(ROOTS.staff, '/offers/view/:offerID?')
    },

    // PRE-ORDER
    preOrder: {
      root: path(ROOTS.staff, '/pre-order'),
    },
    preOrderDetail: {
      view: path(ROOTS.staff, '/pre-order/view/:preOrderID?'),
      viewOrderDetail: path(ROOTS.staff, '/pre-order/view/:preOrderID?/order-detail'),
      edit: path(ROOTS.staff, '/pre-order/view/:preOrderID?/edit')
    }

  }
}