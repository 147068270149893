import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_STAFF } from 'src/routes/paths';


import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import bellFill from '@iconify-icons/eva/bell-fill';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import boxIcon from '@iconify/icons-la/box';
import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';
import personIcon from '@iconify/icons-akar-icons/person';
import reportIcon from '@iconify/icons-carbon/report';

import idCard from '@iconify/icons-bx/id-card';
import groupIcon from '@iconify/icons-el/group';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'pre-order',
    items: [
      {
        title: 'offers',
        icon: ICONS.offers,
        href: PATH_STAFF.general.root
      },
      {
        title: 'pre-order',
        icon: ICONS.preOrder,
        href: PATH_STAFF.general.preOrder.root
      }
    ]
  }
];

export default navConfig;
