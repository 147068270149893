import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  chosenDistributorID: '',
  chosenDistributorName: '',
  holdDistributorID: '',
  holdDistributorName: '',
  listProducts: [],
  isClickNavbar: false,
  isEditConsignmentStockReturnable: false,
  stockReturnableID: ''
}

const slice = createSlice({
  name: 'consignmentStockReturnable',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setChosenDistributorID(state, action) {
      state.isLoading = false;
      state.chosenDistributorID = action.payload
    },

    setChosenDistributorName(state, action) {
      state.isLoading = false;
      state.chosenDistributorName = action.payload
    },

    setHoldDistributorID(state, action) {
      state.isLoading = false;
      state.holdDistributorID = action.payload
    },

    setHoldDistributorName(state, action) {
      state.isLoading = false;
      state.holdDistributorName = action.payload
    },

    setListProducts(state, action) {
      state.isLoading = false;
      state.listProducts = action.payload
    },

    setIsClickNavbar(state, action) {
      state.isLoading = false;
      state.isClickNavbar = action.payload
    },

    setIsEditConsignmentStockReturnable(state, action) {
      state.isLoading = false;
      state.isEditConsignmentStockReturnable = action.payload
    },

    setStockReturnableID(state, action) {
      state.isLoading = false;
      state.stockReturnableID = action.payload
    },

    resetConsignmentStockReturnable(state) {
      state.chosenDistributorID = ''
      state.chosenDistributorName = ''
      state.holdDistributorID = ''
      state.holdDistributorName = ''
      state.listProducts = []
      state.isClickNavbar = false
      state.isEditConsignmentStockReturnable = false
      state.stockReturnableID = ''
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;


// ----------------------------------------------------------------------
export function setChosenDistributorID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenDistributorID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setChosenDistributorName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setChosenDistributorName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setHoldDistributorID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setHoldDistributorID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setHoldDistributorName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setHoldDistributorName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setListProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsClickNavbar(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsClickNavbar(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsEditConsignmentStockReturnable(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsEditConsignmentStockReturnable(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setStockReturnableID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setStockReturnableID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetConsignmentStockReturnable() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetConsignmentStockReturnable())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}