import 'firebase/auth';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { store } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { firebaseConfig } from 'src/config';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { useDispatch } from 'react-redux'
import { getUserDetail, logout } from 'src/redux/slices/authFirebase'
import routes, { renderRoutes } from 'src/routes'
import _ from 'lodash'
import * as rdd from 'react-device-detect'

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  //firebase.firestore();
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: false
  },
  dispatch: store.dispatch,
  //createFirestoreInstance
};


// ----------------------------------------------------------------------

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

function FirebaseProvider({ children }) {
  const { user, isAuthenticated } = useAuth()
  const dispatch = useDispatch()

  const [checkMobile, setCheckMobile] = useState(false)


  useEffect(() => {
    const getInitialize = async () => {
      try {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user && isAuthenticated) {  
            let token = await user.getIdToken()
            console.log('USER: ', user)
            console.log('TOKEN: ', token)
            dispatch(getUserDetail({token: token}))

            setCheckMobile(rdd.isMobileOnly)
          }
          else if(_.isNull(user) && !isAuthenticated){
            dispatch(logout())
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    getInitialize();
  }, [isAuthenticated]);

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      {children}
      {user.status === 'active' ? (
        <>

          {
            checkMobile ? (
              user?.hasSubmitB2BForm === true ? (
                <>
                  {user?.b2bFormStatus === 'approved' ? (
                    user?.isStaff === true ? (
                      renderRoutes(routes.routesStaffMobile)
                    )
                    :
                    (
                      renderRoutes(routes.routesCustomerMobile)
                    )
                    
                  ) 
                  : 
                  (
                    renderRoutes(routes.routesCustomerMobAppReview)
                  )
                }
                </>
              )
              :
              (
                renderRoutes(routes.routesCustomerMobileRegister)
              )
            )
            :
            (
              user?.hasSubmitB2BForm === true ? (
                <>
                  {user?.b2bFormStatus === 'approved' ? (
                    user?.isStaff === true ? (
                      renderRoutes(routes.routesStaff)
                    )
                    :
                    (
                      renderRoutes(routes.routesCustomer)
                    )
                    
                  ) 
                  : 
                  (
                    renderRoutes(routes.routesCustomerAppReview)
                  )
                }
                </>
              )
              :
              (
                renderRoutes(routes.routesCustomerRegister)
              )
            )
          }



          {/**{!_.isEmpty(user?.signUpToDistributor) ? (
            <>
              {user?.hasSubmitB2BForm === true ? (
                <>
                  {user?.b2bFormStatus === 'approved'
                    ? renderRoutes(routes.routesCustomer)
                    : renderRoutes(routes.routesCustomerAppReview)}
                </>
              ) : (
                renderRoutes(routes.routesCustomerRegister)
              )}
            </>
          ) : (
            renderRoutes(routes.routesCustomer)
          )}*/}


        </>
      ) : user.status === 'pending' ? (
        renderRoutes(routes.routesCustomerUnverified)
      ) : (
        renderRoutes(routes.routes)
      )}

      {/* {
        _.isEmpty(user?.signUpToDistributor) ? (
          // old data
          renderRoutes(routes.routesCustomer)
        ) 
        :
        (
          user?.hasSubmitB2BForm === true ? (
            user?.b2bFormStatus === 'pending' || user?.b2bFormStatus === 'rejected' ? (
              renderRoutes(routes.routesCustomerAppReview)
            )
            :
            (
              renderRoutes(routes.routesCustomer)
            )
          )
          :
          (
            renderRoutes(routes.routesCustomerRegister)
          )
        )
      } */}
    </ReactReduxFirebaseProvider>
  );
}

export default FirebaseProvider;
