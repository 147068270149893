import axios from 'axios';
import queryString from 'query-string';

const server_url = process.env.REACT_APP_SERVER_URL;

const getUserByToken = async (token) => {
  return await axios
    .get(`${server_url}/api/customer/getUserByFirebaseID`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateCustomer = async (token, user) => {
  return await axios
    .post(`${server_url}/api/customer/updateCustomer`, user, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

const addNewCustomer = async (token, user) => {
  return await axios
    .post(`${server_url}/api/customer/addNewCustomer`, user, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

const sendEmailVerificationCode = async (token, data) => {
  return await axios
    .post(`${server_url}/api/customer/changeEmail`, data, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const verifyChangeEmail = async (token, data) => {
  return await axios
    .post(`${server_url}/api/customer/verifyChangeEmail`, data, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

const verifyVerificationCodeSignUp = async (data) => {
  return await axios
    .post(`${server_url}/api/customer/verifyVerificationCodeSignUp`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

const updateCustomerB2BFormStatus = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/customer/updateCustomerB2BFormStatus`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const verifyAccountEmailLink = async (params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/customer/verify-account/emailLink?${query}`)
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const checkIsCustomerVerified = async (params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/customer/verification/checkIsCustomerVerified?${query}`)
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const addStaff = async (token, newStaff) => {
  return await axios
    .post(`${server_url}/api/customer/staff/addStaff`, newStaff, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

const changePassword = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/customer/staff/changePassword`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export {
  getUserByToken,
  updateCustomer,
  addNewCustomer,
  sendEmailVerificationCode,
  verifyChangeEmail,
  verifyVerificationCodeSignUp,
  updateCustomerB2BFormStatus,
  verifyAccountEmailLink,
  checkIsCustomerVerified,
  addStaff,
  changePassword
}