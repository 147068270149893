import { PATH_STAFF } from "./paths";
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardStaffLayout from "src/layouts/DashboardStaffLayout";

// ----------------------------------------------------------------------
const AppStaffRoutes = {
  path: PATH_STAFF.root,
  guard: AuthProtect,
  layout: DashboardStaffLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    // DASHBOARD
    {
      exact: true,
      path: PATH_STAFF.general.root,
      component: lazy(() => import('src/views/StaffDashboardGroup/offer/Offers'))
    },

    // OFFERS
    {
      exact: true,
      path: PATH_STAFF.general.offersDetail.view,
      component: lazy(() => import('src/views/StaffDashboardGroup/offer/offer-detail/OfferView2'))
    },

    // PRE-ORDER
    {
      exact: true,
      path: PATH_STAFF.general.preOrder.root,
      component: lazy(() => import('src/views/StaffDashboardGroup/preorder/PreOrder'))
    },
    {
      exact: true,
      path: PATH_STAFF.general.preOrderDetail.view,
      component: lazy(() => import('src/views/StaffDashboardGroup/preorder/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_STAFF.general.preOrderDetail.edit,
      component: lazy(() => import('src/views/StaffDashboardGroup/preorder/PreOrderEdit'))
    }
  ]
}

export default AppStaffRoutes