import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_LOGISTIC } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import outlineAssignmentReturn from '@iconify/icons-ic/outline-assignment-return';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  rma: <Icon icon={outlineAssignmentReturn} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    //subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_LOGISTIC.general.root
      },
      {
        title: 'RMA',
        icon: ICONS.rma,
        href: PATH_LOGISTIC.general.rma
      }
    ]
  }
];

export default navConfig;
