import { PATH_ADMIN_KC } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminKCLayout from 'src/layouts/DashboardAdminKCLayout';

// ----------------------------------------------------------------------

const AppAdminKCRoutes = {
  path: PATH_ADMIN_KC.root,
  guard: AuthProtect,
  layout: DashboardAdminKCLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_ADMIN_KC.general.dashboard,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/dashboard/DashboardAppView'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributor,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.addDistributor,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/AddDistributor'))
    },
    {
      exact: true,
      path: PATH_ADMIN_KC.general.distributorDetails.view,
      component: lazy(() => import('src/views/AdminKCDashboardGroup/distributor/details/DistributorView'))
    }
  ]
}

export default AppAdminKCRoutes