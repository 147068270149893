import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';
import signUpReducer from './slices/signUp';

import offerReducer from './slices/offer';

import applicationFormReducer from './slices/applicationForm';
import consignmentReportReducer from './slices/consignmentReport';
import consignmentStockReturnableReducer from './slices/consignmentStockReturnable';

import authFirebaseReducer from './slices/authFirebase';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const offerPersistConfig = {
  key: 'offer',
  storage: storage,
  keyPrefix: 'redux-'
};

const applicationFormPersistConfig = {
  key: 'applicationForm',
  storage: storage,
  keyPrefix: 'redux-'
}

const consignmentReportPersistConfig = {
  key: 'consignmentReport',
  storage: storage,
  keyPrefix: 'redux-'
}

const consignmentStockReturnablePersistConfig = {
  key: 'consignmentStockReturnable',
  storage: storage,
  keyPrefix: 'redux-'
}

const signUpReducerPersistConfig = {
  key: 'signUp',
  storage: storage,
  keyPrefix: 'redux-'
}

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const authPersistConfigFirebase = {
  key: 'authFirebase',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated', 'user']
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  //firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  applicationForm: persistReducer(applicationFormPersistConfig, applicationFormReducer),
  offer: persistReducer(offerPersistConfig, offerReducer),
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  authFirebase: persistReducer(authPersistConfigFirebase, authFirebaseReducer),
  consignmentReport: persistReducer(consignmentReportPersistConfig, consignmentReportReducer),
  consignmentStockReturnable: persistReducer(consignmentStockReturnablePersistConfig, consignmentStockReturnableReducer),
  signUp: persistReducer(signUpReducerPersistConfig, signUpReducer)
});

export { rootPersistConfig, rootReducer };