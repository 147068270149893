import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_CUSTOMER_MOBILE_REGISTER } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import reportIcon from '@iconify/icons-carbon/report';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Retailer Application Form',
        icon: ICONS.home,
        href: PATH_CUSTOMER_MOBILE_REGISTER.general.retailerApplicationForm
      }
    ]
  }
];

export default navConfig;
