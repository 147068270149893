import { PATH_CUSTOMER, PATH_CUSTOMER_MOBILE, PATH_CUSTOMER_MOBILE_APP_REVIEW } from "./paths";
import React, { lazy } from 'react';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardCustomerMobLayout from "src/layouts/DashboardCustomerMobLayout";

// ----------------------------------------------------------------------------

const AppCustomerMobileRoutes = {
  path: PATH_CUSTOMER_MOBILE,
  guard: AuthProtect,
  layout: DashboardCustomerMobLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.dashboard,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/dashboard/index'))
    },

    // NOTIFICATIONS
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.notifications,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/notifications/Notifications'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.notificationDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/notifications/detail/NotificationView'))
    },

    // OFFERS
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.offers,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/offers/Offers'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.offersDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/offers/offer-detail/OfferView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.offersDetail.offerSuccess,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/offers/offer-detail/OfferSuccess'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.offersDetail.manageRating,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/offers/offer-detail/ManageRating'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.offersDetail.manageRecommendedCartonQty,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/offers/offer-detail/ManageRecommendedCartonQty'))
    },

    // PRE ORDER
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.preOrder,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/pre-order/PreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.preOrderDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/pre-order/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.preOrderDetail.viewOrderDetail,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/pre-order/ViewOrderDetail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.preOrderDetail.staffPreOrderBreakdown,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/pre-order/StaffPreOrderBreakdown'))
    },

    // CLOSED OFFERS
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.closedOffers,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/closed-offers/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.closedOffersDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/closed-offers/closed-offers-detail/ClosedOfferView'))
    },

    // ALL ORDER
    {
      exact: true, 
      path: PATH_CUSTOMER_MOBILE.general.orderAll,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/order-all/OrderAll'))
    },
    // {
    //   exact: true,
    //   path: PATH_CUSTOMER.general.orderAllDetail.view,
    //   component: lazy(() => import('src/views/CustomerDashboardGroup/invoice/detail/InvoiceView2'))
    // },

    // ORDER
    {
      exact: true, 
      path: PATH_CUSTOMER_MOBILE.general.order,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.orderDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/invoice/detail/InvoiceView2'))
    },

    // CONSIGNMENT
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignment,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/order-consignment/OrderConsignment'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/order-consignment/details/OrderConsignmentView'))
    },
    
    // PAYMENT
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.payment,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.paymentDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/payment/PaymentView'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.distributor,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.distributorView,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/distributor/DistributorView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.applyDistributor,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/distributor/apply/ApplyDistributor'))
    },

    // REPORT
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.report,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/report/Report'))
    },

    // PROFILE
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.profile,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/profile/Profile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editProfile,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/profile/EditProfile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.changeEmail,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/profile/ChangeEmail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.changePassword,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/profile/ChangePassword'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.verificationCode,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/profile/VerificationCode'))
    },

    // RETAILER APPLICATION FORM
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.retailerApplicationForm,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/RetailerApplicationForm'))
    },

    // EDIT RETAILER APPLICATION FORM FOR APPROVED STATUS
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.form001,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/Form001'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.form002,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/Form002'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.form003,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/Form003'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.form004,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/Form004'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.form005,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/Form005'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.formAgreement,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/FormAgreementTermsDistributor'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.editRetailerApplicationForm.formSummary,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/edit-retailer-application-form/FormSummary'))
    },

    // CONSIGNMENT REPORT
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentReport,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/ConsignmentReport'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentReport.sale,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/new/AddConsignmentSale'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentReport.addProduct,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/new/ConsignmentAddProduct'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentReportDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/details/ConsignmentReportView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentReport.auditStock,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/new/AddConsignmentAudit'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentReport.addProductForAudit,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-report/new/audit-stock/ConsignmentAddProduct'))
    },

    
    // CONSIGNMENT STOCK RETURNABLE
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentStockReturnable,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-stock-returnable/ConsignmentStockReturnable'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentStockReturnableDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-stock-returnable/details/ConsignmentStockReturnableView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentStockReturnable.new,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-stock-returnable/new/AddConsignmentStockReturnable'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addConsignmentStockReturnable.addProduct,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-stock-returnable/new/ConsignmentAddProduct'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentStockReturnableDetail.edit,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-stock-returnable/details/ConsignmentStockReturnableEdit'))
    },

    // CONSIGNMENT SKU ORDER
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentSkuOrder,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-sku-order/ConsignmentSkuOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.consignmentSkuOrderDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/consignment-sku-order/detail/ConsignmentSkuOrderView'))
    },

    // CONSIGNMENT STOCK PRODUCTS
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.stockProducts,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/stock-products/StockProducts'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.stockProductsDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/stock-products/StockProductsView'))
    },

    // STAFF
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staff,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff/Staff'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.addStaff,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff/new/AddStaff'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff/details/StaffView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffDetail.edit,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff/details/StaffEdit'))
    },

    // STAFF PRE ORDER
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffPreOrder,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff-preorder/StaffPreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffPreOrderDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff-preorder/StaffPreOrderView'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffPreOrderDetail.generatePreOrder,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff-preorder/GeneratePreOrder'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.staffPreOrderDetail.edit,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/staff-preorder/StaffPreOrderEdit'))
    },

    // CREDIT NOTE
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.creditNote,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/credit-note/CreditNote'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE.general.creditNoteDetail.view,
      component: lazy(() => import('src/views/CustomerMobileDashboardGroup/credit-note/detail/CreditNoteView'))
    }
  ]
}

export default AppCustomerMobileRoutes