import jwtDecode from 'jwt-decode'
import axios from 'src/utils/axios'
import { createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import user from './user';
import { getUserByToken } from 'src/api/customer'

// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authFirebase',
  initialState,
  reducers: {
    // START LOADING
    /*
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },
    */

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = {}
    }

    /*
    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
    */
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
/*
const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
*/
// ----------------------------------------------------------------------
/*
export function login({ email, password }) {
  return async (dispatch) => {
    const response = await axios.post('/api/account/login', {
      email,
      password
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

export function login2({ token }) {
  return async (dispatch) => {
    
    let result = await firebase.login({token: token})
    const user = {displayName: 'Mollie', role: 'Customer'};
    dispatch(slice.actions.loginSuccess({ user }));
    
  };
}
*/
export function getUserDetail({token}) {
  return async (dispatch) => {
    
    const user = (await getUserByToken(token)).data
    dispatch(slice.actions.loginSuccess({ user }))

  }
}

export function logout() {
  return async (dispatch) => {
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------
/*
export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/api/account/my-account');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
*/