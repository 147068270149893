import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect, useLocation } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

import _ from 'lodash';
import * as rdd from 'react-device-detect'

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

const domainURL = process.env.REACT_APP_DOMAIN;
function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();
  const location = useLocation()

  console.log('AuthProtect')
  console.log(user)

  let checkMobile = rdd.isMobileOnly

  if (checkMobile) {

    if (location.pathname.includes('/customer/pre-order/view')) {
      let delimiter = '/'
      let start = 2
      
      let tokens = location.pathname.split(delimiter).slice(start)
    
      window.location.href = `${domainURL}/customer-mobile/${tokens.join('/')}`
    }
  }

  
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}

export default AuthProtect;
