import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { 
  PATH_CUSTOMER,
  PATH_CUSTOMER_MOBILE,
  PATH_CUSTOMER_REGISTER,
  PATH_CUSTOMER_MOBILE_REGISTER,
  PATH_CUSTOMER_APP_REVIEW,
  PATH_CUSTOMER_MOBILE_APP_REVIEW,
  PATH_STAFF
} from 'src/routes/paths';
import { Redirect } from 'react-router-dom';
import _ from 'lodash'
import LoadingScreen from 'src/components/LoadingScreen';

import * as rdd from 'react-device-detect'

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  console.log('GUEST: ', user)

  if (isLoading) {
    return <LoadingScreen />;
  }


  if (isAuthenticated && user.status === 'active') {
    // ALL OLD DATA FOR THOSE WHO DID APPLIED TO DISTRIBUTOR BEFORE, WILL BE REGARDED EVER SUBMITTED B2B FORM AND APPROVED
    // THOSE WHO NEVER APPLIED TO DISTRIBUTOR, WILL NEED TO SUBMIT B2B FORM -- TO NORMALIZE THE DATA
    if(user?.hasSubmitB2BForm === true) {
      if(user?.b2bFormStatus === 'approved') {
        let checkMobile = rdd.isMobileOnly

        if (user?.isStaff === true) {
          return <Redirect to={PATH_STAFF.general.root} />
        }

        if (checkMobile) {
          if (!_.isEmpty(localStorage.getItem('offerDirect'))) {
            let offerPath = localStorage.getItem('offerDirect')
            let delimiter = '/'
            let start = 2
            let tokens = offerPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalOfferPath = '/customer-mobile/' + result

            localStorage.clear()
            return <Redirect to={finalOfferPath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('preOrderDirect'))) {
            let preOrderPath = localStorage.getItem('preOrderDirect')
            let delimiter = '/'
            let start = 2
            let tokens = preOrderPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalPreOrderPath = '/customer-mobile/' + result

            localStorage.clear()
            return <Redirect to={finalPreOrderPath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('invoiceDirect'))) {
            let invoicePath = localStorage.getItem('invoiceDirect')
            let delimiter = '/'
            let start = 2
            let tokens = invoicePath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalInvoicePath = '/customer-mobile/' + result

            localStorage.clear()
            return <Redirect to={finalInvoicePath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('closedOfferDirect'))) {
            let closedOfferPath = localStorage.getItem('closedOfferDirect')
            let delimiter = '/'
            let start = 2
            let tokens = closedOfferPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalClosedOfferPath = '/customer-mobile/' + result

            localStorage.clear()
            return <Redirect to={finalClosedOfferPath} />;
          }
          else {
            return <Redirect to={PATH_CUSTOMER_MOBILE.general.root}/>;
          }
          
        }
        else {

          if (!_.isEmpty(localStorage.getItem('offerDirect'))) {
            let offerPath = localStorage.getItem('offerDirect')
            let delimiter = '/'
            let start = 2
            let tokens = offerPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalOfferPath = '/customer/' + result

            localStorage.clear()
            return <Redirect to={finalOfferPath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('preOrderDirect'))) {
            let preOrderPath = localStorage.getItem('preOrderDirect')
            let delimiter = '/'
            let start = 2
            let tokens = preOrderPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalPreOrderPath = '/customer/' + result

            localStorage.clear()
            return <Redirect to={finalPreOrderPath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('invoiceDirect'))) {
            let invoicePath = localStorage.getItem('invoiceDirect')
            let delimiter = '/'
            let start = 2
            let tokens = invoicePath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalInvoicePath = '/customer/' + result

            localStorage.clear()
            return <Redirect to={finalInvoicePath} />;
          }
          else if (!_.isEmpty(localStorage.getItem('closedOfferDirect'))) {
            let closedOfferPath = localStorage.getItem('closedOfferDirect')
            let delimiter = '/'
            let start = 2
            let tokens = closedOfferPath.split(delimiter).slice(start)
            let result = tokens.join(delimiter)

            let finalClosedOfferPath = '/customer/' + result

            localStorage.clear()
            return <Redirect to={finalClosedOfferPath} />;
          }
          else {
            return <Redirect to={PATH_CUSTOMER.general.root} />;
          }
          
        }
       
      }
      else {
        let checkMobile = rdd.isMobileOnly

        if (checkMobile) {
          return <Redirect to={PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationForm} />;
        }
        else {
          return <Redirect to={PATH_CUSTOMER_APP_REVIEW.general.retailerApplicationForm} />;
        }
        
      }
    }
    else {
      let checkMobile = rdd.isMobileOnly

      if (checkMobile) {
        return <Redirect to={PATH_CUSTOMER_MOBILE_REGISTER.general.retailerApplicationForm} />;
      }
      else {
        return <Redirect to={PATH_CUSTOMER_REGISTER.general.retailerApplicationForm} />;
      }
     
    }
  }


  /**if (isAuthenticated && user.status === 'active'){
    if(!_.isEmpty(user?.signUpToDistributor)){
      if(user?.hasSubmitB2BForm === true){
        if(user?.b2bFormStatus === 'approved'){
          return <Redirect to={PATH_CUSTOMER.general.root} />;
        }
        else{
          return <Redirect to={PATH_CUSTOMER_APP_REVIEW.general.retailerApplicationForm} />;
        }
      }
      else{
        return <Redirect to={PATH_CUSTOMER_REGISTER.general.retailerApplicationForm} />;
      }
    }
    else {
      return <Redirect to={PATH_CUSTOMER.general.root} />;
    }
  }*/

  if (isAuthenticated && user.status === 'pending'){
    return <Redirect to={`/register-success/${user.email}/${user.signUpToDistributor}`} />;
  }

  // if (isAuthenticated && _.isEmpty(user?.signUpToDistributor)) {
  //   console.log('HELLO')
  //   return <Redirect to={PATH_CUSTOMER.general.root} />;
  // }

  // if (isAuthenticated && !_.isEmpty(user?.signUpToDistributor)) {
  //   if (user?.hasSubmitB2BForm === true) {
  //     if (user?.b2bFormStatus === 'pending' || user?.b2bFormStatus === 'rejected') {
  //       // 3 MENU ROUTES
  //       console.log('TESTING')
  //       return <Redirect to={PATH_CUSTOMER_APP_REVIEW.general.retailerApplicationForm} />;
  //     }
  //     else {
  //       console.log('JALAN')
  //       return <Redirect to={PATH_CUSTOMER.general.root} />;
  //     }
  //   }
  //   else {
  //     return <Redirect to={PATH_CUSTOMER_REGISTER.general.retailerApplicationForm} />;
  //   }

  // }
  
  return <>{children}</>;
}

export default GuestProtect;
