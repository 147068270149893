import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_CUSTOMER, PATH_STAFF } from 'src/routes/paths';


import { Icon } from '@iconify/react';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import boxIcon from '@iconify/icons-la/box';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'pre-order',
    items: [
      {
        title: 'offers',
        icon: ICONS.offers,
        href: PATH_STAFF.general.root
      },
      {
        title: 'pre-order',
        icon: ICONS.preOrder,
        href: PATH_STAFF.general.preOrder.root
      }
    ]
  }
];

export default navConfig;
