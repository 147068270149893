import { PATH_LOGISTIC } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLogisticLayout from 'src/layouts/DashboardLogisticLayout';

// ----------------------------------------------------------------------
const AppLogisticRoutes = {
  path: PATH_LOGISTIC.root,
  guard: AuthProtect,
  layout: DashboardLogisticLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_LOGISTIC.general.dashboard,
      component: lazy(() => import('src/views/LogisticDashboardGroup/dashboard/DashboardAppView'))
    },

    // RMA
    {
      exact: true,
      path: PATH_LOGISTIC.general.rma,
      component: lazy(() => import('src/views/LogisticDashboardGroup/rma/RMA'))
    },
    {
      exact: true,
      path: PATH_LOGISTIC.general.addRma,
      component: lazy(() => import('src/views/LogisticDashboardGroup/rma/AddRMA'))
    }
  ]
}

export default AppLogisticRoutes